import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Login from "../components/login"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class ProduitsPage extends React.Component {
  state = {
    isAuthentified: false,
    password: "",
    products: {},
    formFields: {
      firstName: "",
      lastName: "",
      address: "",
      npaVille: "",
      phone: "",
      email: "",
      items_list: "",
    },
    prixTotal: 0,
  }

  setIsAuthentified = data => {
    this.setState({ isAuthentified: true, formFields: data })
  }

  handleSubmit = event => {
    let itemsText = ""

    Object.keys(this.state.products).map(key => {
      if (this.state.products[key].quantity > 0) {
        itemsText += `${this.state.products[key].quantity}x ${this.state.products[key].name} // `
      }
      return true
    })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "order_form",
        ...this.state.formFields,
        items_list: itemsText,
      }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))

    event.preventDefault()
  }

  handleFormChange = event => {
    let formFields = { ...this.state.formFields }
    formFields[event.target.name] = event.target.value
    this.setState({ formFields })
  }

  handleQtyChange = event => {
    let products = { ...this.state.products }

    let targetValue = parseInt(event.target.value)
    let minValue = parseInt(event.target.dataset.min)

    if (targetValue <= 0) {
      targetValue = 0
    } else {
      targetValue = Math.max(targetValue, minValue)
    }

    products[event.target.name].quantity = targetValue
    let prixTotal = 0
    for (let productId in products) {
      prixTotal +=
        parseInt(this.state.products[productId].quantity) *
        parseFloat(this.state.products[productId].prixUnitaire)
    }
    this.setState({ products, prixTotal })
  }

  componentDidMount() {
    const { data } = this.props

    const products = {}

    data.products.nodes.forEach(product => {
      products[product.id] = {
        name: product.produit,
        prixUnitaire: product.prixUnitaire,
        quantity: 0,
      }
    })

    this.setState({
      products,
    })
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <Seo title="Accès Pro" />

        {this.state.isAuthentified ? (
          <>
            <div className="d-flex justify-content-between align-items-baseline">
              <h1>Liste des prix</h1>
              <div className="text-right">
                <p>
                  <a
                    className="btn btn-sm btn-primary"
                    download
                    href="/docs/VaporFlavour_Priceliste_V1.7_2021.pdf"
                  >
                    Télécharger la liste de prix
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-5">
              <table className="table table-light table-striped">
                <tbody>
                  <tr className="thead-light">
                    <th style={{ width: "50%" }}>Produits</th>
                    <th style={{ width: "15%" }}>Prix unitaire (CHF)</th>
                    <th style={{ width: "20%" }}>Quantité</th>
                    <th style={{ width: "15%" }}>Total</th>
                  </tr>
                  {data.groups.group.map((category, i) => (
                    <>
                      <tr className="thead-dark" key={i}>
                        <th colSpan="4">
                          <h2 className="mb-0 h3">{category.fieldValue}</h2>
                        </th>
                      </tr>
                      {category.edges.map((edge, i) => (
                        <tr key={i}>
                          <td>{edge.node.produit}</td>
                          <td>{edge.node.prixUnitaire.toFixed(2)}</td>
                          {edge.node.disponibilit_ > 0 ? (
                            <>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  value={
                                    this.state.products[edge.node.id]
                                      ? this.state.products[edge.node.id]
                                          .quantity
                                      : 0
                                  }
                                  data-min={
                                    edge.node.minimumQt_
                                      ? edge.node.minimumQt_
                                      : 1
                                  }
                                  name={edge.node.id}
                                  onChange={this.handleQtyChange}
                                  type="number"
                                />
                                {edge.node.remarque && (
                                  <span className="small text-muted">
                                    {edge.node.remarque}
                                  </span>
                                )}
                              </td>
                              <td className="text-right">
                                {this.state.products[edge.node.id] &&
                                  this.state.products[edge.node.id].quantity >
                                    0 && (
                                    <span>
                                      CHF&nbsp;
                                      {(
                                        this.state.products[edge.node.id]
                                          .quantity * edge.node.prixUnitaire
                                      ).toFixed(2)}
                                    </span>
                                  )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td colSpan="2">
                                <p className="text-muted">
                                  {edge.node.remarque}
                                </p>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
              <form onSubmit={this.handleSubmit} netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="order_form" />
                <p className="d-none">
                  <label>
                    Don’t fill this out if you’re human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>
                <div className="row my-4">
                  <div className="col-6">Total</div>
                  <div className="col-6 text-right">
                    CHF&nbsp;
                    {this.state.prixTotal.toFixed(2)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <h3>Vos contacts</h3>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            placeholder="Prénom"
                            type="text"
                            name="firstName"
                            className="form-control"
                            value={this.state.formFields.firstName}
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            placeholder="Nom"
                            type="text"
                            name="lastName"
                            value={this.state.formFields.lastName}
                            className="form-control"
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            placeholder="Adresse"
                            type="text"
                            name="address"
                            value={this.state.formFields.address}
                            className="form-control"
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            placeholder="NPA / Ville"
                            value={this.state.formFields.npaVille}
                            type="text"
                            name="npaVille"
                            className="form-control"
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            placeholder="Téléphone"
                            type="text"
                            name="phone"
                            value={this.state.formFields.phone}
                            className="form-control"
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            placeholder="Adresse e-mail"
                            type="email"
                            name="email"
                            className="form-control"
                            value={this.state.formFields.email}
                            onChange={this.handleFormChange}
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="alert alert-vf mb-5">
              <p>
                N’hésitez pas à <a href="/contact">nous contacter</a> si vous ne
                trouvez pas ce que vous cherchez dans nos produits.
              </p>
              <p>
                Nous réalisons des mélanges et volumes personnalisé sur demande.
              </p>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-md-4 offset-md-4">
              <Login
                setIsAuthentified={this.setIsAuthentified}
                clients={data.clients.edges}
              />
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  {
    groups: allGooglePricesSheet {
      group(field: cat_gorie) {
        fieldValue
        edges {
          node {
            id
            produit
            cat_gorie
            prixUnitaire
            minimumQt_
            remarque
            disponibilit_
          }
        }
      }
    }
    products: allGooglePricesSheet {
      nodes {
        id
        produit
        prixUnitaire
      }
    }
    clients: allGoogleClientsSheet {
      edges {
        node {
          address
          email
          firstName
          npaVille
          password
          phone
          lastName
        }
      }
    }
  }
`
