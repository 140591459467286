import React from "react"

class Login extends React.Component {
  constructor(props) {
    super()
    this.state = {
      isAuthentified: false,
      email: "",
      password: "",
    }
  }

  handleSubmit = event => {
    this.props.clients.forEach(client => {
      if (
        client.node.email === this.state.email &&
        client.node.password === this.state.password
      ) {
        this.props.setIsAuthentified(client.node)
      }
    })

    event.preventDefault()
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h1>Accès restreint</h1>
        <p>Veuillez rentrer le mot de passe</p>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="email"
            name="email"
            aria-describedby="email"
            placeholder="E-Mail"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            aria-describedby="password"
            placeholder="Mot de passe"
            value={this.state.password}
            onChange={this.handleChange}
          />
        </div>
        <input className="btn btn-primary" type="submit" value="Envoyer" />

        <div className="alert alert-vf my-5">
          <p>
            N’hésitez pas à <a href="/contact">nous contacter</a> si vous
            souhaitez recevoir un accès à cette page.
          </p>
        </div>
      </form>
    )
  }
}

export default Login
